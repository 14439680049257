import React from 'react'

function Refundpolicy() {
    return (
        <div>
            <section class="working-terms">

                <div class="container">
                    <div class="row">

                        <h2 class="working-that"> Refund Policy</h2>
                        <p>How you handle exchanges, refunds, and returns is outlined in the refund policy. This policy is available for display on your website's product page or checkout page. Although we will do everything in our power to direct refund inquiries to you, the seller retains the right to process a refund if needed. "No Refund" policies are legally allowed, but we strongly advises against using them because they frequently result in significant chargebacks from customers. Certain scenarios prevent us from allowing "No Refund" policies. We will notify you if this pertains to you.</p>

                        <h2 class="working-that">An Example of a Goods Refund Policy</h2>
                        <p><strong>You can use this example to establish or modify the return policy for products that will be shown on your website:</strong></p>
                        <p>You have two options if you're not happy with your purchase: you can return it for a complete refund or swap it out for something different. After the purchase date, you have up to 120 days to return or exchange your item. Products that are exchanged or returned must be in their original packaging and/or box, in the same condition that you received them.</p>




                    </div>
                </div>
            </section>
        </div>
    )
}

export default Refundpolicy





