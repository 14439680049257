import React from 'react'

function DeliveryPolicy() {
    return (
        <div>
            <section class="working-terms">

                <div class="container">
                    <div class="row">

                        <h2 class="working-that"> Delivery Policy
                        </h2>
                        <p>The delivery policy may change based on the kind of product you purchase. A few sample policies are provided below; select the one that best fits the nature of your product.</p>
                        <h2 class="working-that">Delivery Guidelines for Material Goods</h2>
                        <p>•	<strong>Availability:</strong></p>
                        <p>We will ship the available portion of your order to you if an item is unavailable. </p>
                        <p>•	<strong>Transporting:</strong></p>
                        <p>We will ship the available portion of your order to you if an item is unavailable. We will dispatch the other items in your order as soon as that item is available. Your order's non-refundable shipping charges are determined by the quantity and weight of the items you order and your location. Your order may arrive on a different date depending on the carrier's shipping policies, the delivery area, the method, and the quantity of things you ordered. Your order may also arrive in multiple shipments.</p>
                        <h2 class="working-that">Extra Fees and Taxes: </h2>
                        <p> Your location and the scope of your order will determine if additional delivery costs are necessary. It is your responsibility to pay any taxes, including value-added tax, sales tax, excise tax, and customs charges, that apply to the delivery of your order. Five days from the time your order is delivered, you have five days from the time your order is delivered to notify us if any of the things you ordered are damaged.</p>
                        <p>You have two options if you're not happy with your purchase: you can return it for a complete refund or swap it out for something different. After the purchase date, you have up to 120 days to return or exchange your item. Products that are exchanged or returned must be in their original packaging and/or box, in the same condition that you received them.</p>

                        <h2 class="working-that"> An Example of a Goods Refund Policy</h2>
                        <p><strong>You can use this example to establish or modify the return policy for products that will be shown on your website:</strong></p>
                        <h2 class="working-that">Product Delivery Guidelines for Digital</h2>
                        <p>After we receive your order, you will either be sent an email with instructions to finish your download, or you will be requested to start your download right away. Should you receive a request to initiate the download right away, doing so will count as delivery of the item or items you bought to you.</p>
                        <p>Should you receive an email from us containing instructions to finish your download, your acceptance of the email will be considered our delivery of the item or items you bought.</p>
                        <p>Within five days after the date of your order, you must get in touch with us if you are having trouble downloading the item(s) you bought or if you have not received an email from us with information on how to finish your download. The item(s) you ordered will be deemed received, downloaded, and delivered to you if you do not get in touch with us within five days of the date of your order.</p>

                        <h2 class="working-that"> Policy for Service Delivery</h2>
                        <p>The services will be rendered to you in compliance with the terms that apply to the services you purchased after your order is received. The timing of the services' performance may vary depending on the type of services you bought and when you bought them. When the services are performed, it will be considered that they have been successfully given to you.</p>


                    </div>
                </div>
            </section>


        </div>
    )
}

export default DeliveryPolicy
